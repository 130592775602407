import { action, computed, observable, makeObservable } from 'mobx'
import { IAdmin } from '~/pages/AccountSettings/AccountSettings.interface'
import { ID } from '~/common.interface'
import {
  CampaignType,
  IInAppPayload,
  ICampaignModel
} from '~/dataStore/Campaign/Campaign.interface'
import Shared from '~/dataStore/Campaign/Shared'
import { getCampaignTypeOptions } from '~/dataStore/CampaignTypeFacade'

import InAppLarge from './InAppLarge.model'
import InAppSmall from './InAppSmall.model'
import StepStore from '~/dataStore/emailBuilder/StepStore'
import { IRegisteredField } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { PartialPreview } from '~/pages/Campaign/CampaignReview/CampaignReview.interface'
import { IInappLargeDTO, NotificationType } from '../../Notification.interface'
import NotificationGoals from '../../NotificationGoals'
import { IAppDetails } from '~/dataStore/App.interface'

export default class InAppNotification extends StepStore {
  public notificationId: ID | undefined = undefined

  public notification: InAppLarge | InAppSmall

  public size: 'large' | 'small' = 'large'

  public appDetails: Pick<IAppDetails, 'id' | 'featureFlags'>

  private isSingleCampaign: boolean

  public shared = new Shared()

  public goals: NotificationGoals | undefined

  constructor({
    app,
    campaign
  }: {
    app: Pick<IAppDetails, 'id' | 'featureFlags'>
    campaign?: ICampaignModel
  }) {
    super()

    makeObservable(this, {
      notification: observable,
      size: observable,
      setSize: action.bound,
      setAdmin: action.bound,
      validateStep: action.bound,
      registeredFields: computed
    })

    this.appDetails = app
    this.isSingleCampaign = !!campaign

    this.notification = new InAppLarge(app.id, app, undefined, false)

    this.initGoals()
  }

  public initGoals(): void {
    this.goals = !this.isSingleCampaign ? new NotificationGoals() : undefined
  }

  public setSize(size: 'large' | 'small'): void {
    if (this.size === size) {
      return
    }

    this.size = size

    if (size === 'large') {
      this.notification = new InAppLarge(
        this.appDetails.id,
        this.appDetails,
        undefined,
        false
      )
    } else {
      this.notification = new InAppSmall(this.appDetails.id)
    }

    this.initGoals()
  }

  public setAdmin(admin?: IAdmin): void {
    if (!this.notification.admin) {
      this.notification.setAdmin(admin)
    }
  }

  validateStep(): void {
    this.notification.validateStep()
    this.goals?.validate([{ sourceType: 'button', active: true }])

    this.beenValid = true
  }

  public get registeredFields(): IRegisteredField[] {
    return [...this.notification.registeredFields, this.goals].filter(
      Boolean
    ) as IRegisteredField[]
  }

  public isSmallPersonal(): boolean {
    return (
      this.notification instanceof InAppSmall && this.notification.isPersonal
    )
  }

  public fillStore({
    inAppNotification
  }: {
    inAppNotification?: IInAppPayload | { large: IInappLargeDTO }
  }): void {
    if (!inAppNotification) {
      return
    }

    let goals
    if ('small' in inAppNotification) {
      this.setSize('small')
      if (this.notification instanceof InAppSmall) {
        this.notification.fillStore(inAppNotification.small)
        // TODO: goals in small inapp payload
      }
    } else {
      this.setSize('large')
      if (this.notification instanceof InAppLarge) {
        const parts =
          'frontParts' in inAppNotification.large
            ? inAppNotification.large.frontParts
            : inAppNotification.large
        this.notification.fillStore(parts)

        if ('goals' in inAppNotification.large) {
          goals = inAppNotification.large.goals
        }
        if (
          'id' in inAppNotification.large &&
          !inAppNotification.large.template
        ) {
          this.notificationId = inAppNotification.large.id
        }
      }
    }

    if (this.goals && goals) {
      if (this.notification instanceof InAppSmall) {
        this.goals.fillGoals(goals, [this.notification.cta])
      } else {
        this.goals.fillGoals(goals, this.notification.actionButtons.buttons)
      }
    }
    this.beenValid = true
  }

  public async getPayload(): Promise<IInAppPayload | IInappLargeDTO> {
    if (this.notification instanceof InAppLarge) {
      const payload = await this.notification.getPayload()
      if (this.isSingleCampaign) {
        return { large: payload }
      }

      return {
        frontParts: payload,
        type: NotificationType.IN_APP,
        size: 'large',
        id: this.notificationId,
        goals: this.goals?.getPayload() || []
      }
    }
    return this.notification.getPayload()
  }

  public generatePreview(): PartialPreview {
    return {
      inApp: {
        position: 'top',
        size: this.size,
        ...this.notification?.generatePreview(),
        goals: this.goals?.getPayload()
      },
      type: getCampaignTypeOptions([CampaignType.IN_APP])
    }
  }
}
