import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { uniqueId } from 'lodash'
import cn from 'classnames'
import { withStore } from '~/dataStore'
import UploadMediaPreview from './components/UploadMediaPreview'
import { FileMimeType } from '~/dataStore/Campaign/Campaign.interface'
import dropZoneImage from './components/dropZoneImage.svg'
import imagePlaceholder from './components/image-placeholder.svg'
import videoPlaceholder from './components/video-placeholder.svg'

import './UploadImage.scss'

interface IProps {
  image?: string | null
  fileName: string | undefined
  small?: boolean
  onChange: (
    objectURL: string,
    fileName: string,
    file: File | null,
    valid: boolean
  ) => void
  invalid?: boolean
  richMedia?: boolean
  allowedTypes: string[]
  fileType?: FileMimeType
  maxFileSizes: { type: FileMimeType; maxSize: number; error: string }[]
  rounded?: boolean
  className?: string
}

function UploadImage({
  onChange,
  image,
  fileName,
  small,
  invalid,
  richMedia = false,
  allowedTypes,
  fileType = 'image',
  maxFileSizes,
  rounded,
  className
}: IProps): ReactElement {
  const [error, setError] = useState<string | null>(null)
  const [withCrop, setWithCrop] = useState(true)
  const inputFileKey = useRef(uniqueId('inputFile'))
  const inputFileRef = useRef(null)
  const uploadImageFormRef = useRef<HTMLDivElement>(null)

  function showDndOverlay(): void {
    uploadImageFormRef.current?.classList.add('upload-image__form--dragover')
  }

  function hideDndOverlay(): void {
    uploadImageFormRef.current?.classList.remove('upload-image__form--dragover')
  }

  function handleRemove(): void {
    if (image) {
      URL.revokeObjectURL(image)
    }
    onChange('', '', null, true)
    inputFileKey.current = uniqueId()
  }

  function getTypeValidator(
    file: File
  ): undefined | { type: FileMimeType; maxSize: number; error: string } {
    const currentFileType = file.type.split('/')[0]
    return maxFileSizes.find((item) => item.type === currentFileType)
  }

  function validateFile(file: File): boolean {
    const typeValidator = getTypeValidator(file)

    if (typeValidator && file.size > typeValidator.maxSize) {
      handleRemove()
      setError(`File size exceeded ${typeValidator.error}!`)
      return false
    }

    if (
      !file.name ||
      !allowedTypes.includes(file.name.split('.').pop() || '')
    ) {
      handleRemove()
      setError(
        `File must contain the valid extension: ${allowedTypes.join(', ')}`
      )
      return false
    }

    return true
  }

  function isGif(name: string): boolean {
    return !!name.split('.').pop()?.includes('gif')
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setError(null)
    if (!e.target.files?.[0]) {
      return
    }

    if (!validateFile(e.target.files[0])) {
      onChange('', '', null, false)
      return
    }
    const file = URL.createObjectURL(e.target.files[0])
    onChange(file, e.target.files[0].name, e.target.files[0], true)
  }

  useEffect(() => {
    inputFileKey.current = uniqueId()
  }, [fileName])

  useEffect(() => {
    setWithCrop(!isGif(fileName || ''))
  }, [fileName])

  return (
    <div
      className={cn('position-relative upload-image-wrapper', className, {
        'upload-image-wrapper--rounded': rounded
      })}>
      {!image && (
        <div
          className={cn('position-relative upload-image', {
            'upload-image--rich-media': richMedia
          })}>
          <div
            ref={uploadImageFormRef}
            onDragOver={showDndOverlay}
            onDragLeave={hideDndOverlay}
            className={cn(
              'upload-image__form position-relative d-flex flex-column justify-content-center align-items-center',
              { 'upload-image__form--small': small }
            )}>
            <div className="upload-image__dnd-overlay">
              <div className="justify-content-center flex-column d-flex text-center">
                <img
                  src={dropZoneImage}
                  width={37}
                  alt="Drop image to upload"
                  className="mx-auto"
                />
                <p className="text-14 mb-0 mt-2">
                  {allowedTypes.includes('mp4')
                    ? 'Drop image or video to upload'
                    : 'Drop image to upload'}
                </p>
              </div>
            </div>
            <input
              data-testid="file"
              key={inputFileKey.current}
              ref={inputFileRef}
              id="upload-file"
              onDrop={hideDndOverlay}
              className="upload-image__input"
              type="file"
              name="file"
              accept={allowedTypes.map((type) => `.${type}`).join(',')}
              onDragOver={(e) => {
                e.preventDefault()
              }}
              onChange={handleChange}
            />
            <div className={cn('d-flex', { 'error-label': invalid })}>
              <div className="d-grid text-center ">
                <img
                  src={imagePlaceholder}
                  className={cn('mx-auto', { 'w-50': small })}
                />
                <>
                  <p
                    className={cn('mt-3 mb-1 fw-medium color-pulsate-blue', {
                      'error-label': invalid
                    })}>
                    Upload photo
                  </p>
                  {!small && <p className="text-14">.png, .jpeg or .gif</p>}
                </>
              </div>
              {!small && allowedTypes.includes('mp4') && (
                <>
                  <div className="divider horizontal fw-light">OR</div>
                  <div className="d-grid text-center ms-1">
                    <div>
                      <img src={videoPlaceholder} className="mx-auto" alt="" />
                      <p
                        className={cn(
                          'mt-3 mb-1 fw-medium color-pulsate-blue',
                          {
                            'error-label': invalid
                          }
                        )}>
                        Upload video
                      </p>
                      <p className="text-14">.mp4</p>
                    </div>
                  </div>
                </>
              )}
            </div>
            {error && (
              <div
                data-testid="error"
                className={cn('error-label upload-image__error text-center', {
                  'mt-3': !small,
                  'upload-image__error--small position-absolute': small
                })}>
                {error}
              </div>
            )}
          </div>
        </div>
      )}

      {image && (
        <UploadMediaPreview
          small={small}
          mediaFile={image}
          mediaType={fileType}
          fileName={fileName || ''}
          withCrop={withCrop}
          onChange={onChange}
        />
      )}
    </div>
  )
}

export default withStore(UploadImage)
