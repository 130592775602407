import { action, computed, observable, makeObservable } from 'mobx'
import {
  CardBackPayload,
  ICardPayload,
  IPreviewItem,
  PreviewFieldType,
  PreviewParts
} from '~/dataStore/Campaign/Campaign.interface'
import {
  IRegisteredField,
  ValidationTypes
} from '~/dataStore/emailBuilder/EmailBuilder.interface'
import NewRegisteredField from '~/dataStore/emailBuilder/RegisteredField.model'
import imagePlaceholder from '~/components/icons/image_placeholder.svg'
import ToggleDNDRegisteredField, {
  IToggleDNDField
} from '~/dataStore/emailBuilder/Fields/ToggleDND.field'
import ImageField from '~/dataStore/emailBuilder/Fields/Image.field'
import CTAGroup from '~/dataStore/emailBuilder/Fields/CTAGroup.field'
import {
  IToggleDNDTableField,
  ToggleDNDTableField
} from '~/dataStore/emailBuilder/Fields/Table.field'
import { ButtonCategory, IAppDetails } from '~/dataStore/App.interface'
import StepStore from '~/dataStore/emailBuilder/StepStore'
import { ToggleAble } from '~/utils/mixins'
import { convertFileToURL } from '~/utils/file.service'
import { ICardDTO } from '../../Notification.interface'
import NotificationGoals from '../../NotificationGoals'
import {
  IToggleRichMedia,
  ToggleRichMedia
} from '~/dataStore/emailBuilder/Fields/RichMedia.field'

export type DNDFields = 'headline' | 'image' | 'text' | 'table'
export type ToggleFields = DNDFields | 'actionButtons'

export default class CardBack extends StepStore {
  public fieldOption = new NewRegisteredField<boolean>(true)

  public headline: IToggleDNDField<string>

  public image: ImageField = new ImageField({
    errorMessage: 'You must upload an Image or turn off section',
    active: true,
    position: 0,
    value: '',
    fileName: ''
  })

  public text: IToggleDNDField<string>

  public table: IToggleDNDTableField

  public actionButtons = new CTAGroup()

  public appDetails: Pick<IAppDetails, 'id' | 'featureFlags'>

  public goals: NotificationGoals | undefined

  hideRichMedia: boolean

  hideImage: boolean

  public richMedia: IToggleRichMedia

  constructor({
    app,
    goals
  }: {
    app: Pick<IAppDetails, 'id' | 'featureFlags'>
    goals?: NotificationGoals | undefined
  }) {
    super()

    makeObservable(this, {
      headline: observable,
      image: observable,
      text: observable,
      table: observable,
      actionButtons: observable,
      validateFieldOption: action.bound,
      toggleFields: computed,
      registeredFields: computed,
      replaceFieldPosition: action.bound,
      hideRichMedia: observable,
      hideImage: observable
    })

    this.appDetails = app

    this.richMedia = new ToggleRichMedia('')
    this.richMedia.setErrorMessage(
      `You must add a Rich Media or turn off section`
    )
    this.richMedia.setPosition(0)

    this.headline = new ToggleDNDRegisteredField<string>('')
    this.headline.setErrorMessage(
      'You must create a Headline or turn off section'
    )
    this.headline.setPosition(1)

    this.text = new ToggleDNDRegisteredField<string>('')
    this.text.setErrorMessage('You must add your Text or turn off section')
    this.text.setPosition(2)

    this.table = new ToggleDNDTableField('')
    this.table.setPosition(3)

    this.setButtonsCategory = this.setButtonsCategory.bind(this)

    this.goals = goals

    if (!app?.featureFlags.cardRichMedia) {
      this.hideRichMedia = true
      this.richMedia.setActive(false)
    }

    if (app?.featureFlags.cardRichMedia) {
      this.hideRichMedia = false
      this.richMedia.setActive(true)
      this.hideImage = true
      this.image.setActive(false)
    }
  }

  public validateFieldOption(): void {
    this.fieldOption.resetError()
    if (this.toggleFields.every((field) => !field.isActive)) {
      this.fieldOption.isValid = false
      this.fieldOption.errors.push({
        type: ValidationTypes.REQUIRED,
        message: 'You must select at least one Feed Post section'
      })
    }
  }

  public validateStep(): void {
    this.validateFieldOption()
    this.image.validate()
    this.headline.validate()
    this.text.validate()
    this.table.validate()
    this.actionButtons.validate()
    this.richMedia.validate()
    this.actionButtons.validateLabels()
    this.goals?.validate()

    this.beenValid = true
  }

  // TODO: Refactor Image and CTA models to use mixins
  public get toggleFields(): (
    | ToggleAble
    | CTAGroup
    | ImageField
    | IToggleRichMedia
  )[] {
    return [this.headline, this.text, this.table, this.image, this.richMedia]
  }

  public get registeredFields(): IRegisteredField[] {
    return [
      this.fieldOption,
      this.headline,
      this.text,
      this.table,
      ...this.table.rows,
      this.image.field,
      ...this.actionButtons.registeredFields,
      this.goals,
      this.richMedia
    ].filter(Boolean) as IRegisteredField[]
  }

  public fillStore({
    cardNotification
  }: {
    cardNotification?: ICardDTO | ICardPayload
  }): void {
    if (!cardNotification?.backParts) {
      return
    }

    const { backParts } = cardNotification

    this.headline.fillField({
      value: backParts.headline.text,
      isActive: backParts.headline.active,
      position: backParts.headline.position
    })

    this.text.fillField({
      value: backParts.text.text,
      isActive: backParts.text.active,
      position: backParts.text.position
    })

    if (backParts.image) {
      this.image.fillField({
        value: backParts.image.url,
        fileName: backParts.image.name,
        isActive: backParts.image.active,
        position: backParts.image.position
      })
    }

    if (backParts.richMedia) {
      this.richMedia.fillField({
        fileName: backParts.richMedia.data?.name,
        value: backParts.richMedia.url,
        isActive: backParts.richMedia.active,
        position: backParts.richMedia.position,
        provider: backParts.richMedia.provider,
        extension: backParts.richMedia.data?.type
      })
    }

    this.table.fillField({
      isActive: backParts.table.active,
      value: backParts.table.heading ?? backParts.heading?.text,
      position: backParts.table.position,
      rows: backParts.table.rows
    })

    if (
      backParts.callToAction.active &&
      backParts.callToAction.buttons?.length
    ) {
      this.setButtonsCategory(`${backParts.callToAction.buttons.length}`)
    }
    this.actionButtons.fillField({
      isActive: !!backParts.callToAction.active,
      buttons: backParts.callToAction.buttons,
      side: 'back'
    })
    this.beenValid = true
  }

  private notifyChange(): void {
    // Trigers autosave
    this.fieldOption.setValue(!this.fieldOption.value)
  }

  public reset(fieldName: ToggleFields): void {
    this.setActive(fieldName, false)
  }

  public setActive(fieldName: ToggleFields, value: boolean): void {
    this[fieldName].setActive(value)
    this.notifyChange()
  }

  public replaceFieldPosition(destination: DNDFields, source: DNDFields): void {
    const destinationPosition = this[destination].position
    const sourcePosition = this[source].position
    this[destination].setPosition(sourcePosition)
    this[source].setPosition(destinationPosition)

    this.notifyChange()
  }

  public setButtonsCategory(value: string): void {
    let buttonCategory: ButtonCategory | undefined
    switch (value) {
      case '1':
        buttonCategory = {
          id: '1',
          buttonLabels: ['']
        }
        break
      case '2':
        buttonCategory = {
          id: '2',
          buttonLabels: ['', '']
        }
        break
      default:
        buttonCategory = undefined
    }

    this.actionButtons.selectGroup(buttonCategory)
    this.actionButtons.validateCategory()
  }

  private async convertRichMediaFileToURL(): Promise<void> {
    if (this.image.file) {
      const response = await convertFileToURL(
        this.image.file,
        this.appDetails.id
      )
      if (response) {
        URL.revokeObjectURL(this.image.value)
        this.image.setValue(response)
        this.image.setFile(null)
      }
    }
    if (this.richMedia.file) {
      const response = await convertFileToURL(
        this.richMedia.file,
        this.appDetails.id
      )
      if (response) {
        URL.revokeObjectURL(this.richMedia.value)
        this.richMedia.setValue(response)
        this.richMedia.setFile(null)
      }
    }
  }

  public async getPayload(): Promise<CardBackPayload> {
    await this.convertRichMediaFileToURL()

    const payload: CardBackPayload = {
      ...(!this.hideImage && {
        image: {
          name: this.image.name,
          active: this.image.isActive,
          position: this.image.position,
          url: this.image.value,
          side: 'back'
        }
      }),
      ...(!this.hideRichMedia && {
        richMedia: {
          active: this.richMedia.isActive,
          name: this.richMedia.fileName,
          position: this.richMedia.position,
          provider: this.richMedia.provider,
          url: this.richMedia.value,
          side: 'back'
        }
      }),
      headline: {
        active: this.headline.isActive,
        text: this.headline.value,
        position: this.headline.position,
        side: 'back'
      },
      text: {
        active: this.text.isActive,
        text: this.text.value,
        position: this.text.position,
        side: 'back'
      },
      table: {
        active: this.table.isActive,
        position: this.table.position,
        heading: this.table.value,
        rows: this.table.isActive ? this.table.getRowsPayload() : [],
        side: 'back'
      },
      callToAction: {
        active: this.actionButtons.isActive,
        buttons: this.actionButtons.payload,
        side: 'back'
      }
    }

    return payload
  }

  public generatePreview(admin?: IPreviewItem): PreviewParts {
    const parts: (IPreviewItem & { position: number })[] = [
      {
        type: PreviewFieldType.ADMIN_HEADER,
        admin: {}
      }
    ]

    if (admin) {
      parts[0] = { ...admin, position: -1 }
    }

    let anyIsActive = false

    if (this.image.isActive) {
      anyIsActive = true
      parts.push({
        position: this.image.position,
        type: PreviewFieldType.IMAGE,
        value: this.image.value || imagePlaceholder
      })
    }

    if (this.richMedia.isActive) {
      anyIsActive = true
      parts.push({
        position: this.richMedia.position,
        type: PreviewFieldType.RICH_MEDIA,
        value: this.richMedia.value,
        mediaType: this.richMedia.fileMimeType
      })
    }

    if (this.headline.isActive) {
      anyIsActive = true
      parts.push({
        position: this.headline.position,
        type: PreviewFieldType.HEADLINE,
        value: this.headline.value || 'Give your Feed Post a Headline'
      })
    }

    if (this.text.isActive) {
      anyIsActive = true
      parts.push({
        position: this.text.position,
        type: PreviewFieldType.TEXT,
        value:
          this.text.value ||
          'Use this section to describe this Feed Post and what it is offering to your customers. You can also turn on the table element to add extra information you want to highlight about this campaign. Link these optional buttons to a URL, Deeplink to a section in your app for extra campaign engagement.'
      })
    }

    if (this.table.isActive) {
      anyIsActive = true
      parts.push({
        position: this.table.position,
        type: PreviewFieldType.HEADLINE_TABLE,
        value: this.table.value || 'Your Table Headline'
      })

      const rows = this.table.getRowsPayload()

      parts.push({
        position: this.table.position,
        type: PreviewFieldType.TABLE,
        value: rows.map((row) => ({
          value: row.value || 'Eg. Monday',
          label: row.label || 'Eg. 9am - 5pm'
        }))
      })
    }

    if (this.actionButtons.isActive && anyIsActive) {
      parts.push({
        position: 4,
        type: PreviewFieldType.BUTTON,
        value: this.actionButtons.buttons.map((button) => ({
          label: button.value || 'Button Text',
          txtColor: button.txtColor.value,
          btnColor: button.btnColor.value
        }))
      })
    }

    return { parts: parts.sort((a, b) => a.position - b.position) }
  }
}
