/* eslint-disable jsx-a11y/anchor-is-valid */
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import UploadImage from '../uploadImage/UploadImage'
import './RichMedia.style.scss'
import Giphy from './Giphy'
import ExternalMedia from './ExternalMedia'
import { FileMimeType, Provider } from '~/dataStore/Campaign/Campaign.interface'
import UploadMediaPreview from '../uploadImage/components/UploadMediaPreview'

interface IProps {
  media?: string
  fileName?: string
  onInternalFileChange: (
    objectURL: string,
    fileName: string,
    file: File | null
  ) => void
  onExternalURLChange: (url: string) => void
  onGiphyChange: (url: string) => void
  provider: Provider
  changeProvider: (provider: Provider) => void
  fileType: FileMimeType
  withCrop?: boolean
  withIosSupportAlert?: boolean
}

export default function RichMedia({
  onInternalFileChange,
  media,
  fileName,
  provider,
  changeProvider,
  onExternalURLChange,
  onGiphyChange,
  fileType,
  withCrop,
  withIosSupportAlert
}: IProps): React.ReactElement {
  function showProvider(value: Provider): () => void {
    return () => changeProvider(value)
  }

  function setIsActiveGiphy(isActive: boolean): void {
    changeProvider(isActive ? Provider.GIPHY : Provider.S3)
  }

  function resetProvider() {
    changeProvider(Provider.S3)
  }

  if (provider !== Provider.GIPHY && media && fileName) {
    return (
      <>
        <UploadMediaPreview
          fileName={fileName}
          mediaFile={media}
          mediaType={fileType}
          cropTooltip={
            <>
              Image must have a minimum width of 320px <br />
              Image in portrait orientation takes most of Push space
            </>
          }
          withCrop={
            typeof withCrop !== 'undefined' ? withCrop : fileType === 'image'
          }
          onChange={onInternalFileChange}
        />

        <div className="my-3 bg-blue-gray p-2 d-block text-center text-white d-flex align-items-center justify-content-center">
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="text-white me-2"
            size="lg"
          />
          Video, GIF and audio files are for iOS only
        </div>
      </>
    )
  }

  return (
    <div className="rich-media mb-3">
      <div className="position-relative upload-image px-4 py-3">
        <div>
          {provider !== Provider.GIPHY && (
            <div>
              <UploadImage
                richMedia
                onChange={onInternalFileChange}
                image={media}
                fileName={fileName}
                withLabel={false}
                fileType={fileType}
                allowedTypes={['jpg', 'png', 'jpeg', 'gif', 'mp4', 'mp3']}
                maxFileSizes={[
                  { type: 'image', maxSize: 5_000_000, error: '5MB' },
                  { type: 'audio', maxSize: 5_000_000, error: '5MB' },
                  { type: 'video', maxSize: 30_000_000, error: '30MB' }
                ]}
              />
              <div className="upload-image__text text-center">
                Upload or enter{' '}
                <button
                  type="button"
                  className="btn text-primary btn-link btn--transparent text-14"
                  onClick={showProvider(Provider.EXTERNAL)}>
                  URL
                </button>{' '}
                for an image, video, GIF or audio
              </div>
            </div>
          )}
          {provider === Provider.S3 && (
            <>
              <div className="divider fw-light my-4">OR</div>
            </>
          )}
          {provider !== Provider.EXTERNAL && (
            <Giphy
              showWholeView={provider === Provider.GIPHY}
              selected={media}
              setIsActiveGiphy={setIsActiveGiphy}
              onGiphyChange={onGiphyChange}
            />
          )}
          {provider === Provider.EXTERNAL && (
            <ExternalMedia
              hide={resetProvider}
              onURLChange={onExternalURLChange}
            />
          )}
          {withIosSupportAlert && (
            <div className="bg-blue-gray p-2 d-block text-center text-white d-flex align-items-center justify-content-center">
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="text-white me-2"
                size="lg"
              />
              Video, GIF and audio files are for iOS only
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
