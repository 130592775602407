import { action, computed, observable, makeObservable } from 'mobx'
import { PartialPreview } from '~/pages/Campaign/CampaignReview/CampaignReview.interface'
import {
  CampaignType,
  ICardPayload,
  ICampaignModel,
  PreviewFieldType,
  CardParts
} from '~/dataStore/Campaign/Campaign.interface'
import { getCampaignTypeOptions } from '~/dataStore/CampaignTypeFacade'
import { IRegisteredField } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import InAppLarge from '../../InAppNotification/Model/InAppLarge.model'
import StepStore from '~/dataStore/emailBuilder/StepStore'
import CardBack from '~/pages/Campaign/Notification/CardNotification/Model/CardBack.model'
import { IAdmin } from '~/pages/AccountSettings/AccountSettings.interface'
import { ID } from '~/common.interface'
import Shared from '~/dataStore/Campaign/Shared'
import NotificationGoals from '../../NotificationGoals'
import { ICardDTO, NotificationType } from '../../Notification.interface'
import { IAppDetails } from '~/dataStore/App.interface'

export default class CardNotification extends StepStore {
  public notificationId: ID | undefined = undefined

  public cardFront: InAppLarge

  public cardBack: CardBack

  public shared = new Shared()

  private isSingleCampaign: boolean

  public goals: NotificationGoals | undefined

  constructor({
    appId,
    app,
    campaign
  }: {
    appId: ID
    app?: Pick<IAppDetails, 'id' | 'featureFlags'>
    campaign?: ICampaignModel
  }) {
    super()

    makeObservable(this, {
      cardFront: observable,
      cardBack: observable,
      isCardBack: computed,
      setAdmin: action.bound,
      validateStep: action.bound,
      registeredFields: computed
    })

    this.isSingleCampaign = !!campaign

    this.cardFront = new InAppLarge(
      appId,
      app,
      'You must select at least one Feed Post section',
      true
    )

    if (!this.isSingleCampaign) {
      this.goals = new NotificationGoals()
    }

    this.cardBack = new CardBack({ app, goals: this.goals })
  }

  public setAdmin(admin?: IAdmin): void {
    if (!this.cardFront.admin?.id) {
      this.cardFront.setAdmin(admin)
    }
  }

  private isCardBackActive(): boolean {
    return this.cardFront.actionButtons.buttons.some((ab) => ab.isCardBack)
  }

  public get isCardBack(): boolean {
    return this.isCardBackActive()
  }

  public validateStep(): void {
    this.cardFront.validateStep()
    if (!this.isCardBackActive()) {
      this.goals?.validate()
    }

    this.beenValid = true
  }

  public get registeredFields(): IRegisteredField[] {
    const fields = [...this.cardFront.registeredFields, this.goals]

    if (this.isCardBack && this.goals) {
      fields.pop()
    }

    return fields.filter(Boolean) as IRegisteredField[]
  }

  public fillStore({
    cardNotification
  }: {
    cardNotification?: ICardDTO | ICardPayload
  }): void {
    if (!cardNotification) {
      return
    }
    this.cardFront.fillStore(cardNotification.frontParts)
    if ('id' in cardNotification && !cardNotification.template) {
      this.notificationId = cardNotification.id
    }

    if (this.isCardBack) {
      this.cardBack.fillStore({ cardNotification })
    }

    if ('goals' in cardNotification && this.goals && cardNotification.goals) {
      this.goals.fillGoals(cardNotification.goals, [
        ...this.cardFront.actionButtons.buttons,
        ...this.cardBack.actionButtons.buttons
      ])
    }
    this.beenValid = true
  }

  public async getPayload(): Promise<ICardPayload | ICardDTO> {
    const payload: ICardPayload = {
      frontParts: await this.cardFront.getPayload()
    }

    if (this.isCardBackActive()) {
      payload.backParts = await this.cardBack.getPayload()

      if (!this.isSingleCampaign) {
        let tableHeading = {}
        if (payload.backParts.table.heading) {
          tableHeading = {
            heading: {
              active: payload.backParts.table.active,
              position: payload.backParts.table.position,
              text: payload.backParts.table.heading,
              side: 'back'
            }
          }
        }
        payload.backParts = {
          adminHeaderWithMessage: {
            ...payload.frontParts.adminHeaderWithMessage,
            side: 'back'
          },
          ...payload.backParts,
          ...tableHeading,
          table: {
            active: payload.backParts.table.active,
            position: payload.backParts.table.position,
            rows: payload.backParts.table.rows,
            side: 'back'
          }
        }
      }
    }

    payload.id = this.notificationId
    payload.type = NotificationType.CARD
    payload.goals = this.goals?.getPayload() || []

    return payload
  }

  public generatePreview(): PartialPreview {
    const front = this.cardFront.generatePreview()

    const card: { front: CardParts; back?: CardParts } = {
      front: {
        ...front,
        goals: this.goals?.getPayload()
      }
    }

    if (this.isCardBackActive()) {
      const back = this.cardBack.generatePreview(
        front.parts.find((part) => part.type === PreviewFieldType.ADMIN_HEADER)
      )

      card.back = {
        ...back,
        goals: this.goals?.getPayload()
      }
    }

    return {
      card,
      type: getCampaignTypeOptions([CampaignType.CARD], { card })
    }
  }
}
