import cn from 'classnames'
import { Button } from 'reactstrap'
import { X } from 'react-feather'
import React, { useEffect, useState } from 'react'
import ReactGiphySearchbox from 'react-giphy-searchbox'
import './RichMedia.style.scss'

interface IProps {
  setIsActiveGiphy: (isActive: boolean) => void
  onGiphyChange: (url: string) => void
  selected?: string
  showWholeView: boolean
}

export default function Giphy({
  setIsActiveGiphy,
  onGiphyChange,
  selected,
  showWholeView
}: IProps): React.ReactElement {
  const [giphyColWidth, setGiphyColWidth] = useState(133)
  const [searchboxKey, setSearchboxKey] = useState(false)
  const [isWholeView, setIsWholeView] = useState(false)

  function calculateColWidth(elem: HTMLDivElement): void {
    if (!elem) return

    setGiphyColWidth((elem.clientWidth - 30) / 3)
  }

  useEffect(() => {
    if (selected || showWholeView) {
      setIsWholeView(true)
    }
  }, [])

  return (
    <>
      <div ref={calculateColWidth} className="position-relative">
        {!!giphyColWidth && (
          <ReactGiphySearchbox
            key={`${searchboxKey}`}
            apiKey="gh22xugcKJ248"
            searchPlaceholder="Search for a GIF..."
            masonryConfig={[
              {
                columns: 3,
                imageWidth: giphyColWidth,
                gutter: 10
              }
            ]}
            onSelect={(item: { images: { original: { url: string } } }) => {
              onGiphyChange(item.images.original.url.split('?')[0])
            }}
            onSearch={(search: string) => {
              if (search) {
                setIsActiveGiphy(true)
                setIsWholeView(true)
              }
            }}
            wrapperClassName="w-100 mb-4"
            searchFormClassName={cn('giphy-wrapper', {
              'giphy-wrapper--active': isWholeView
            })}
            listWrapperClassName={cn({ 'd-none': !isWholeView })}
          />
        )}
        {isWholeView && (
          <Button
            color=""
            className="p-0 giphy-wrapper__close"
            onClick={() => {
              setIsActiveGiphy(false)
              setIsWholeView(false)
              setSearchboxKey(!searchboxKey)
            }}>
            <X className="cursor-pointer p-0 remove-icon" />
          </Button>
        )}
      </div>
      {selected && (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => onGiphyChange('')}
          className="position-absolute giphy-selected d-flex justify-content-center align-items-center cursor-pointer"
          onClick={() => onGiphyChange('')}>
          <button
            onClick={(e) => e.stopPropagation()}
            type="button"
            className="btn--transparent m-auto giphy-selected__btn">
            <img src={selected} alt="giphy" className="giphy-selected__img" />
          </button>
        </div>
      )}
    </>
  )
}
