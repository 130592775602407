import { Dictionary } from 'lodash'
import { INotificationGoalDTO } from '~/pages/Campaign/Notification/NotificationGoals/NotificationGoals.interface'
import { ID } from '~/common.interface'
import {
  IDesign,
  IOption,
  IRegisteredField,
  Trigger,
  ITimeWindow
} from '../emailBuilder/EmailBuilder.interface'
import { IAdmin } from '~/pages/AccountSettings/AccountSettings.interface'

export enum PreviewFieldType {
  HEADLINE = 'headline',
  ADMIN_HEADER = 'admin_header_with_message',
  APP_HEADER = 'app_header',
  IMAGE = 'image',
  ATTACHMENT = 'attachment',
  RICH_MEDIA = 'richmedia',
  SUBHEADLINE = 'subheadline',
  TEXT = 'text',
  HEADLINE_TABLE = 'headline_table',
  TABLE = 'table',
  BUTTON = 'button',
  IMAGE_HEADER_WITH_MESSAGE = 'image_header_with_message'
}

export enum CampaignType {
  CARD = 'card',
  CARD_FRONT = 'cardfront',
  CARD_BACK = 'cardback',
  PUSH = 'push',
  ALERT = 'alert',
  IN_APP = 'in_app',
  SMS = 'sms',
  EMAIL = 'email'
}

export enum InAppSize {
  SMALL = 'small',
  LARGE = 'large'
}

export enum CampaignStatus {
  ACTIVE = 'Active',
  PAUSED = 'Paused',
  DRAFT = 'Draft',
  SCHEDULED = 'Scheduled',
  DELIVERED = 'Delivered'
}

export enum ActionTypes {
  CARD = 'card',
  THIS_CARD = 'thiscard',
  CARD_BACK = 'card_back',
  DEEPLINK = 'deeplink',
  URL = 'url',
  OPEN_FEED = 'openfeed',
  DISMISS = 'dismiss',
  OPEN_APP = 'openapp'
}

export enum Provider {
  EXTERNAL = 'external',
  S3 = 's3',
  GIPHY = 'giphy'
}

export type FileMimeType = 'image' | 'audio' | 'video'
export type FileExtension = VideoExtension | AudioExtension | ImageExtension

export type VideoExtension = 'mp4' | 'mpeg' | 'mpg' | 'wav' | 'avi'
export type AudioExtension = 'mp3' | 'm4a' | 'aiff'
export type ImageExtension = 'png' | 'jpg' | 'jpeg' | 'gif'

export interface ICampaign
  extends ISetupPayload,
    ITargetingPayload,
    IDeliveryPayload {
  id?: string
  duplicationSourceId: string | null
  stateMachineNotificationsState?: 'draft' | 'initial'
  type: CampaignNotificationType
  typeList?: CampaignType[]
  lastBuilderPage: string | number
  authorName: string
  imageUrl?: string
  displayType?: string
  status?: CampaignStatus
  allowReply?: boolean
  broadcastType?: string
  createdAt?: string
  deliveredAt?: string
  groupIds?: unknown[]
  guid?: string
  hidden?: boolean
  hiddenAt?: string
  notificationTemplate?: unknown
  state?: CampaignStatus
  subscriptionsCount?: number
  target?: string
  thumbnailUrl?: string
  updatedAt?: string
  emailNotification?: IEmailPayload
  smsNotification?: ISMSPayload
  inAppNotification?: IInAppPayload
  pushVariants?: IPushPayload[]
  cardNotification?: ICardPayload
}

export interface ISetupPayload {
  goalsAttributes?: {
    primary: boolean
    eventKind: 'in_app_event' | 'open_app'
    expiryTimeUnit: string
    expiryTimeValue: string
    eventIdentifier: string
  }[]
  goals?: {
    primary: boolean
    eventKind: 'in_app_event' | 'open_app'
    expiryTimeUnit: string
    expiryTimeValue: string
    eventIdentifier?: string | null
  }[]
  name: string
  controlGroup:
    | {
        unit: 'percent'
        quantity: number | string
      }
    | null
    | number
}

export interface IEmailPayload {
  source: string
  subject: string
  design: IDesign | undefined | string
  html: string
  fromName: string
  originTemplateId?: string
  id?: ID
  name?: string
  template?: boolean
}

export interface ISMSPayload {
  bodyTemplate: string
}

export type IInAppPayload = LargeInappPayload | SmallInappPayload

export type LargeInappPayload = {
  large: ICardInAppPayload
}

export type SmallInappPayload = { small: SmallPayload }

export type SmallPayload = {
  callToAction: {
    buttons: [CTAButton]
  }
  imageHeaderWithMessage: {
    url: string
    name: string
    message: string
  }
  adminHeaderWithMessage: {
    position: number
    active: boolean
    message: string
    admin: Pick<IAdmin, 'jobTitle' | 'name' | 'avatarUrl' | 'id'>
  }
  position: 'top' | 'bottom'
  type: 'corporate' | 'personal'
}

export type ICardPayload = CardFrontPayload &
  Partial<{ backParts: CardBackPayload }>

export type CardFrontPayload = {
  frontParts: ICardInAppPayload
}

export interface CardBackPayload {
  adminHeaderWithMessage?: {
    position: number
    active: boolean
    message: string
    admin: Pick<IAdmin, 'jobTitle' | 'name' | 'avatarUrl' | 'id'>
  }
  headline: {
    active: boolean
    text: string
    position: number
    side: 'back'
  }
  text: {
    active: boolean
    text: string
    position: number
    side: 'back'
  }
  image: {
    active: boolean
    url: string
    name: string
    message?: string
    position: number
    side: 'back'
  }
  richMedia: {
    active: boolean
    url: string
    name: string
    provider: Provider
    position: number
  }
  table: {
    heading?: string
    active: boolean
    position: number
    rows?: IOption[]
    side: 'back'
  }
  callToAction: {
    active: boolean
    buttons: CTAButton[]
    side: 'back'
  }
}

export interface ICardInAppPayload {
  adminHeaderWithMessage: {
    position: number
    active: boolean
    message: string
    admin: Pick<IAdmin, 'jobTitle' | 'name' | 'avatarUrl' | 'id'>
  }
  headline: {
    active: boolean
    text: string
    position: number
  }
  text: {
    active: boolean
    text: string
    position: number
  }
  richMedia: {
    active: boolean
    url: string
    name: string
    provider: Provider
    position: number
    data?: {
      name: string | null
      type: FileExtension
    }
  }
  image: {
    active: boolean
    url: string
    name: string
    position: number
  }
  callToAction: {
    buttons: [CTAButton]
  }
}

export type CTAButton = {
  label?: string
  destination?: string
  destinationType: ActionTypes
  inAppEvents?: string[]
  orderNumber?: number
  btnColor?: string
  txtColor?: string
}
export interface IPushPayload {
  bodyTemplate: string
  buttonCategoryId?: string
  destination?: string
  destinationType?: ActionTypes
  subtitleTemplate?: string
  titleTemplate?: string
  buttons?: CTAButton[]
  activeFields?: {
    titleTemplate: boolean
    subtitleTemplate: boolean
    buttons: boolean
  }
  attachment: {
    active: boolean
    url?: string | null
    provider: Provider
    name?: string
    data?: {
      name: string | null
      type: FileExtension
    } | null
  }
}

export interface ITargetingPayload {
  segmentIds: string[]
  beaconIds: string[]
  geofenceIds: string[]
  inAppEventNames?: string[]
  beaconEvents: Dictionary<Trigger | null | undefined>
  geofenceEvents: Dictionary<Trigger | null | undefined>
  geofenceDwellingTimes: Dictionary<string | number | undefined>
}

export interface IDeliveryPayload {
  startNow: string
  startAt: string
  endAt: string | Date
  timeWindows?: ITimeWindow[]
  timeFrame: string
  timeValue: string
  timeZoneName: string
  timeZoneOffset: string
  delivery: string
  campaignLimits: string
  campaignExpiry?: boolean
  expiryDate?: string
  expiryTimeFrame?: string
  expiryTimeValue?: string | number
}

export interface ICampaignModel {
  shared: unknown
  rootStore: unknown
  stepStores: StepStoreFactory[]
  campaignId: string | undefined
  status: CampaignStatus | undefined
  preview: IPreview | undefined
  mode: 'details' | 'builder'
  disposers: (() => void)[]
  currentStep: ICurrentStep
  forceShowTooltip: boolean
  hasChanged: boolean
  isCampaignSaving: boolean | null
  getAppId: () => string | undefined
  addDisposer: (disposer: () => void) => void
  isCardOrInAppCampaign: boolean
  isCardInAppCampaign: boolean
  isCardCampaign: boolean
}

export type StepStoreFactory = {
  store: IStepStore
  name: string
  displayName: string
  position: number
}

export interface IStepStore {
  beenValid: boolean
  validateStep: () => void
  isStepValid: boolean
  registeredFields: IRegisteredField[]
  getErrorMessages: () => string[]
  fillStore: <T extends ICampaign>(data: T) => void
  shared?: unknown
}

export interface ICurrentStep {
  number: number
  store: IStepStore
}

export enum CampaignNotificationType {
  CARD_INBOX = 'CardInboxCampaign',
  CARD_IN_APP = 'CardInAppNotificationCampaign',
  CARD_PUSH_NOTIFICATIONS = 'CardPushNotificationCampaign',
  CARD_SMS_NOTIFICATION = 'CardSmsNotificationCampaign',
  PUSH_NOTIFICATION = 'PushNotificationCampaign',
  ALERT_NOTIFICATION = 'AlertNotificationCampaign',
  IN_APP_NOTIFICATION = 'InAppNotificationCampaign',
  CARD_EMAIL_NOTIFICATION = 'CardEmailNotificationCampaign',
  EMAIL_NOTIFICATION_CAMPAIGN = 'EmailNotificationCampaign',
  SMS_NOTIFICATION_CAMPAIGN = 'SmsNotificationCampaign'
}

export interface IPreview {
  push: {
    variants: PreviewParts[]
  }
  inApp: InappParts
  card: {
    front: CardParts
    back: CardParts
  }
  sms: PreviewParts
  email: EmailParts
  type: CampaignType[]
}

export interface IButtonPreviewPart {
  label: string
  btnColor?: string
  txtColor?: string
}

export type PreviewParts = {
  parts: IPreviewItem[]
  goals?: INotificationGoalDTO[]
}

export type CardParts = PreviewParts

export type InappParts = PreviewParts & {
  position: 'top' | 'bottom'
  size: 'small' | 'large'
}

export type EmailParts = {
  html?: string
  imageUrl?: string
  notification: {
    fromName: string
    emailAddress: string
    html: string
  }
  goals?: INotificationGoalDTO[]
}
export type IPreviewType =
  | { push: { variants: PreviewParts[] } }
  | { inApp: InappParts }
  | {
      card: {
        front: CardParts
        back: CardParts
      }
    }
  | { sms: PreviewParts }
  | { email: EmailParts }

export type IPreviewParts = PreviewParts | CardParts | InappParts | EmailParts

export interface IPreviewItem {
  type: PreviewFieldType
  value?: string | string[] | IOption[] | IButtonPreviewPart[]
  admin?: {
    jobTitle?: string | null
    name: string
    avatarUrl: string
  }
  appLogo?: string
  appName?: string
  image?: string
  mediaType?: FileMimeType | 'gif'
  position?: number
}

export type DeviceImageType =
  | 'ios'
  | 'iosAppBg'
  | 'iosPush'
  | 'iosCardFront'
  | 'iosCardBack'
  | 'android'
  | 'androidAppBg'
  | 'androidPush'
  | 'androidCardFront'
  | 'androidCardBack'
  | 'watch'
  | 'desktop'

export type DeviceType = 'ios' | 'android' | 'watch' | 'desktop'
