import { action, computed, observable, makeObservable, override } from 'mobx'
import {
  FileMimeType,
  FileExtension,
  Provider
} from '~/dataStore/Campaign/Campaign.interface'
import { ToggleAble, ToggleMixin } from '~/utils/mixins'
import NewRegisteredField from '../RegisteredField.model'

class RichMedia extends NewRegisteredField<string> {
  public provider = Provider.S3

  fileName = ''

  file: File | null = null

  extenstion: FileExtension | undefined

  position: number

  constructor(initialValue: string, beenValid?: boolean) {
    super(initialValue, beenValid)

    makeObservable(this, {
      position: observable,
      provider: observable,
      fileName: observable,
      file: observable,
      extenstion: observable,
      setPosition: action.bound,
      fillField: override,
      fileMimeType: computed,
      previewFileType: computed,
      setProvider: action.bound,
      setFile: action.bound,
      setExtension: action.bound,
      setFileName: action.bound
    })

    this.addInternalFile = this.addInternalFile.bind(this)
    this.addExternalFile = this.addExternalFile.bind(this)
    this.addGiphy = this.addGiphy.bind(this)
  }

  public fillField({
    fileName,
    provider,
    extension,
    position,
    ...rest
  }: {
    value: string
    fileName: string
    provider: Provider
    position: number
    extension: FileExtension
    isActive: boolean
  }): void {
    super.fillField(rest)
    this.setPosition(position ?? this.position)
    this.fileName = fileName
    this.setProvider(provider)
    this.setExtension(extension)
  }

  public get fileMimeType(): FileMimeType {
    switch (this.extenstion) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return 'image'
      case 'mp4':
        return 'video'
      case 'mp3':
        return 'audio'
      default:
        return 'image'
    }
  }

  public get previewFileType(): FileMimeType | 'gif' {
    if (this.extenstion === 'gif') {
      return 'gif'
    }

    return this.fileMimeType
  }

  public setProvider(provider: Provider): void {
    this.provider = provider || Provider.S3
  }

  public setFile(file: File | null): void {
    this.file = file
  }

  public setPosition(value: number): void {
    this.position = value
  }

  public setExtension(ext: FileExtension): void {
    this.extenstion = ext
  }

  public setFileName(value: string): void {
    this.fileName = value
  }

  public addExternalFile(url: string): void {
    const fileName = url.split('/').pop() || ''
    const extenstion = fileName.split('.').pop() || 'jpg'

    this.setProvider(Provider.EXTERNAL)
    this.setValue(url)
    this.setFileName(fileName)
    this.setExtension(extenstion as FileExtension)
    this.validate()
  }

  public addGiphy(url: string): void {
    this.setProvider(Provider.GIPHY)
    this.setValue(url)
    this.setExtension('gif')
    this.validate()
  }

  public addInternalFile(
    objectURL: string,
    fileName: string,
    file: File | null
  ): void {
    const extenstion = fileName.split('.').pop() || 'jpg'
    this.setProvider(Provider.S3)
    this.setFile(file)
    this.setValue(objectURL)
    this.setFileName(fileName)
    this.setExtension(extenstion as FileExtension)
    this.validate()
  }
}

export default RichMedia

export type IToggleRichMedia = ToggleAble & RichMedia
export const ToggleRichMedia = ToggleMixin(RichMedia)
