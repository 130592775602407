import { uniqueId } from 'lodash'
import React, { ReactElement } from 'react'
import AppHeader from './AppHeader'
import Media from './Media'
import CallToActionButtons from './CallToActionButtons'
import AdminHeader from './AdminHeader'
import Headline from './Headline'
import Text from './Text'
import PreviewMergeTagRichText from './PreviewMergeTagRichText'
import TablePreview from './TablePreview'
import {
  IPreviewItem,
  PreviewFieldType
} from '~/dataStore/Campaign/Campaign.interface'
import { isString } from '~/utils/utilities'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import useCollapseContext from '../../../../../components/CollapseWithToggle/CollapseWithToggle.context'

interface IProps {
  parts: IPreviewItem[]
}

export default function PreviewParts({ parts }: IProps): ReactElement {
  const collapseContext = useCollapseContext()

  return (
    <>
      {parts.map((part) => {
        const key = `${part.type}-${uniqueId()}`

        const onClick = collapseContext
          ? () => collapseContext.setOpened(part.type)
          : undefined
        switch (part.type) {
          case PreviewFieldType.ADMIN_HEADER:
            return (
              <AdminHeader
                onClick={onClick}
                key={key}
                image={part.admin?.avatarUrl || ''}
                name={part.admin?.name || ''}
                jobTitle={part.admin?.jobTitle || ''}
                message={(part.value as string) || ''}
              />
            )
          case PreviewFieldType.IMAGE_HEADER_WITH_MESSAGE:
            return (
              <React.Fragment key={key}>
                <Media mediaFile={part.image} onClick={onClick} />
                <Text text={part.value} onClick={onClick} />
              </React.Fragment>
            )
          case PreviewFieldType.APP_HEADER:
            return (
              <AppHeader
                key={key}
                name={part.appName}
                logo={part.appLogo}
                onClick={onClick}
              />
            )
          case PreviewFieldType.IMAGE:
          case PreviewFieldType.ATTACHMENT:
          case PreviewFieldType.RICH_MEDIA:
            return (
              <Media
                key={key}
                mediaFile={part.value}
                mediaType={part.mediaType}
                onClick={onClick}
              />
            )
          case PreviewFieldType.HEADLINE:
          case PreviewFieldType.SUBHEADLINE:
          case PreviewFieldType.HEADLINE_TABLE:
            if (isString(part.value)) {
              return <Headline key={key} text={part.value} onClick={onClick} />
            }
            break
          case PreviewFieldType.TEXT:
            if (typeof part.value === 'string' && part.value.includes('<')) {
              return <PreviewMergeTagRichText text={part.value} />
            }
            return <Text key={key} text={part.value} onClick={onClick} />
          case PreviewFieldType.TABLE:
            return (
              <TablePreview
                key={key}
                rows={part.value as IOption[]}
                onClick={onClick}
              />
            )
          case PreviewFieldType.BUTTON:
            return (
              <CallToActionButtons
                key={key}
                buttons={part.value as string[]}
                onClick={onClick}
              />
            )
          default:
            return null
        }

        return null
      })}
    </>
  )
}
