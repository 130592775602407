import { ReactElement, useState } from 'react'
import Textarea from '~/components/forms/Textarea/Textarea.field'
import RichTextEditor from '~/components/forms/RichTextTextarea/RichTextEditor'
import UploadImage from '~/components/uploadImage/UploadImage'
import withDND from '~/components/DND'
import { withStore } from '~/dataStore'
import { isEmpty } from '~/utils/utilities'
import { ActionTypes } from '~/dataStore/Campaign/Campaign.interface'
import CardBackModel, {
  DNDFields,
  ToggleFields
} from '~/pages/Campaign/Notification/CardNotification/Model/CardBack.model'
import CollapseWithToggle from '~/components/CollapseWithToggle'
import useCollapseContext from '~/components/CollapseWithToggle/CollapseWithToggle.context'
import Shared from '~/dataStore/Campaign/Shared'
import CampaignCTAGroupButton from '~/pages/CampaignBuilder/CTA/CampaignCTAGroupButton'
import CampaignBuilderTable from '~/pages/CampaignBuilder/CampaignBuilderTable'
import NotificationGoals from '../../NotificationGoals'
import RichMedia from '~/components/RichMedia/RichMedia'

interface IProps {
  notification: CardBackModel
  shared: Shared
  isSended: boolean
  withGoals?: boolean
  notificationGoals?: NotificationGoals
  withEvent?: boolean
}

const categoryOptions = [
  { value: '1', name: '1 Button' },
  { value: '2', name: '2 Buttons' }
]

const ToggleWithDND = withDND(CollapseWithToggle)

function CardBack({
  notification,
  shared,
  isSended,
  withGoals,
  notificationGoals,
  withEvent
}: IProps): ReactElement {
  // TODO: Refactor all components that use DND and toggle components. Create hook or HOC with this functionality
  const { opened: openPicker, setOpened: setOpenPicker } =
    useCollapseContext() || {}
  const [dragged, setDragged] = useState<{ type: DNDFields } | undefined>()

  function setPosition(type: DNDFields): void {
    if (dragged) {
      notification.replaceFieldPosition(type, dragged.type)
    }
  }

  function handleCheck(type: ToggleFields): (check: boolean) => void {
    return (check: boolean) => {
      if (!isSended) {
        if (!check) {
          notification.reset(type)

          if (type === 'actionButtons') {
            notificationGoals?.removeGoals('button', 'back')
          }
        } else {
          notification.setActive(type, true)
        }
        notification.validateFieldOption()
        notification[type].validate()
      }
    }
  }

  const pickerProps = (type: ToggleFields, name: string) => ({
    type,
    name,
    disabled: isSended,
    opened: openPicker === type,
    setPosition,
    setDragged: (dragged) => {
      setDragged(dragged)
      setOpenPicker?.(null)
    },
    isValid: notification.fieldOption.isValid && notification[type].isValid,
    setOpened: (open: boolean) => {
      setOpenPicker?.(open ? type : null)
    },
    checked: !isEmpty(notification[type as ToggleFields].isActive)
      ? notification[type as ToggleFields].isActive
      : true,
    setChecked: handleCheck(type as ToggleFields)
  })

  return (
    <>
      <div className="d-flex flex-column">
        {!notification.hideImage && (
          <ToggleWithDND
            {...pickerProps('image', 'Image')}
            withSeparator
            position={notification.image.position}>
            <UploadImage
              className="mb-3"
              allowedTypes={['jpg', 'png', 'jpeg', 'gif']}
              onChange={notification.image.addInternalFile}
              image={notification.image.value}
              fileName={notification.image.name}
              maxFileSizes={[
                { type: 'image', maxSize: 1_000_000, error: '1MB' }
              ]}
            />
          </ToggleWithDND>
        )}
        {!notification.hideRichMedia && (
          <ToggleWithDND
            {...pickerProps('richMedia', 'Media')}
            withSeparator
            position={notification.richMedia.position}>
            <RichMedia
              onInternalFileChange={notification.richMedia.addInternalFile}
              onExternalURLChange={notification.richMedia.addExternalFile}
              onGiphyChange={notification.richMedia.addGiphy}
              media={notification.richMedia.value}
              fileName={notification.richMedia.fileName}
              provider={notification.richMedia.provider}
              changeProvider={notification.richMedia.setProvider}
              fileType={notification.richMedia.fileMimeType}
              withCrop={notification.richMedia.previewFileType === 'image'}
            />
          </ToggleWithDND>
        )}
        <ToggleWithDND
          {...pickerProps('headline', 'Headline')}
          withSeparator
          position={notification.headline.position}>
          <Textarea
            onChange={notification.headline.setValue}
            value={notification.headline.value}
            invalid={!notification.headline.isValid}
            required
            withoutLineBreaks
            disabled={isSended}
            onBlur={notification.headline.validate}
            placeholder="Add a Headline that grabs people’s attention... "
            mergeTags={shared.mergeTags}
          />
        </ToggleWithDND>
        <ToggleWithDND
          {...pickerProps('text', 'Text')}
          withSeparator
          position={notification.text.position}>
          <RichTextEditor
            mergeTags={shared.mergeTags}
            placeholder="Tell people more about your campaign..."
            onBlur={notification.text.validate}
            onChange={notification.text.setValue}
            disabled={isSended}
            invalid={!notification.text.isValid}
            value={notification.text.value}
          />
        </ToggleWithDND>
        <ToggleWithDND
          {...pickerProps('table', 'Table')}
          withSeparator
          position={notification.table.position}>
          <CampaignBuilderTable field={notification.table} />
        </ToggleWithDND>
      </div>
      <CollapseWithToggle {...pickerProps('actionButtons', 'Call to Action')}>
        <CampaignCTAGroupButton
          key="actionButtons"
          deeplinks={shared.deeplinksOptions}
          categoryOptions={categoryOptions}
          notificationActionTypes={[
            ActionTypes.DEEPLINK,
            ActionTypes.URL,
            ActionTypes.DISMISS
          ]}
          withGoals={withGoals}
          notificationGoals={notificationGoals}
          side="back"
          onlyNotificationAction={false}
          eventsOptions={shared.eventsOptions}
          onSelect={(value: string) => {
            notification.setButtonsCategory(value)
            notificationGoals?.removeGoals('button', 'back')
          }}
          onBlur={notification.actionButtons.validateLabels}
          selected={notification.actionButtons.buttonCategory.value}
          buttons={notification.actionButtons.buttons}
          withEvent={withEvent}
        />
      </CollapseWithToggle>
    </>
  )
}

export default withStore(CardBack)
