import PushNotification from '~/pages/Campaign/Notification/PushNotification/Model/PushNotification'
import Deliver from '../emailBuilder/Deliver/Deliver'
import Review from '../emailBuilder/Review'
import SetUp from '../emailBuilder/SetUp'
import StepStore from '../emailBuilder/StepStore'
import Targeting from '../emailBuilder/Targeting/Targeting'
import { CampaignType, ICampaignModel } from './Campaign.interface'
import SMS from '../emailBuilder/SMS/SMS'
import InAppCampaign from './InAppCampaign'
import { getParams } from '~/routes'
import CardNotification from '../../pages/Campaign/Notification/CardNotification/Model/CardNotification'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'

type Store = {
  store: StepStore
  name: string
  displayName: string
}

export default class CardCampaign {
  static createStepStores(
    campaign: ICampaignModel,
    notification?: CampaignType
  ): {
    store: StepStore
    name: string
    displayName: string
    position: number
  }[] {
    const card = new CardNotification({
      appId: campaign.rootStore?.app.currentApp.id,
      app: campaign.rootStore?.app.appDetails,
      campaign
    })

    const steps: Store[] = [
      {
        name: 'setUp',
        displayName: 'Set Up',
        store: new SetUp(campaign)
      }
    ]

    const params = getParams('campaignBuilder')
    const notificationType: CampaignType | undefined =
      notification || params?.notificationType

    if (notificationType) {
      const notificationStep = CardCampaign.initNotificationStore(
        campaign,
        notificationType
      )
      if (notificationStep) {
        steps.push(notificationStep)
      }
    }

    const targeting = new Targeting(campaign)

    steps.push(
      {
        name: 'cardFront',
        displayName: MCampaignTypeToName.get(CampaignType.CARD_FRONT) || '',
        store: card
      },
      {
        name: 'targeting',
        displayName: 'Target',
        store: targeting
      },
      {
        name: 'deliver',
        displayName: 'Deliver',
        store: new Deliver(campaign, targeting)
      },
      {
        name: 'review',
        displayName: 'Review',
        store: new Review()
      }
    )

    return steps.map((step, index: number) => ({
      ...step,
      position: index + 1
    }))
  }

  static initNotificationStore(
    campaign: ICampaignModel,
    notificationType?: CampaignType
  ): Store | undefined {
    switch (notificationType) {
      case CampaignType.PUSH:
        return {
          name: 'push',
          displayName: 'Push',
          store: new PushNotification({
            app: campaign.rootStore?.app.appDetails,
            campaign,
            isCardCampaign: campaign.isCardCampaign
          })
        }
      case CampaignType.IN_APP:
        return InAppCampaign.initInAppStore(campaign)
      case CampaignType.SMS:
        return {
          name: 'sms',
          displayName: 'SMS',
          store: new SMS(campaign)
        }
      default:
        return undefined
    }
  }
}
