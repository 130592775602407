import { uniqueId } from 'lodash'
import { action, makeObservable, observable, override } from 'mobx'
import { CustomErrors, ID } from '~/common.interface'
import { IAppDetails } from '~/dataStore/App.interface'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import NewRegisteredField from '~/dataStore/emailBuilder/RegisteredField.model'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'
import { PartialPreview } from '~/pages/Campaign/CampaignReview/CampaignReview.interface'
import AlertNotification from '~/pages/Campaign/Notification/AlertNotification/Model/AlertNotification'
import InAppNotification from '~/pages/Campaign/Notification/InAppNotification/Model/InAppNotification'
import {
  fetchDefaultNotificationTemplate,
  fetchNotification,
  fetchNotificationTemplate,
  saveNotification,
  updateNotification
} from '~/pages/Campaign/Notification/Notification.connector'
import {
  NotificationPayload,
  NotificationType
} from '~/pages/Campaign/Notification/Notification.interface'
import { GoalType } from '~/pages/Campaign/Notification/NotificationGoals/NotificationGoals.interface'
import PushNotification from '~/pages/Campaign/Notification/PushNotification/Model/PushNotification'
import { updateBlock } from '~/pages/Journeys/Connector/Journeys.connector'
import {
  getGoalTypesFromNotification,
  getPreviewFromNotification,
  getSplitNodesBeforeNextMessage
} from '~/pages/Journeys/Journey.service'
import JourneyCard from '../JourneyNotificationSidebar/CardNotificationSidebar/JourneyCard'
import JourneyEmailNotificationStore from '../JourneyNotificationSidebar/EmailNotificationSidebar/Store/JourneyEmailNotificationStore'
import {
  IBlock,
  INotification,
  JourneyBlock,
  JourneyBlockType,
  NotificationModel
} from '../Store/JourneyBuilder.interface'
import Block from './Block.model'

type MessageBlockDTO = {
  name: string
  options: INotification[]
}

function isNotification(n: INotification): n is INotification & {
  notification: NotificationModel
} {
  return !!n.notification
}

export default class Message extends Block<MessageBlockDTO> {
  public name = new NewRegisteredField<string>('')

  public options: INotification[] = [
    {
      position: 0,
      id: uniqueId(),
      notifications: [
        {
          id: null,
          position: 0
        }
      ],
      type: undefined,
      notification: undefined,
      isValid: true
    }
  ]

  public selectedNotification: INotification | null = null

  constructor(
    public parent: IBlock,
    public currentApp: Pick<IAppDetails, 'id' | 'image' | 'name'>
  ) {
    super(JourneyBlockType.MESSAGE, parent)
    makeObservable<Message, 'fillBlockPayload'>(this, {
      name: observable,
      options: observable,
      selectedNotification: observable,
      setSelectedNotification: action.bound,
      fillBlockPayload: action.bound,
      validateName: action.bound,
      validateNotifications: action.bound,
      validateGoalTypes: action.bound,
      addEmptyNotification: action.bound,
      setNotification: action.bound,
      replaceNotificationPosition: action.bound,
      removeNotification: action.bound,
      resetError: override,
      saveNotification: action.bound
    })

    this.setName = this.setName.bind(this)
    this.rollbackNotification = this.rollbackNotification.bind(this)
  }

  get filled(): boolean {
    return !!this.name.value
  }

  public setSelectedNotification(notification: INotification | null): void {
    this.selectedNotification = notification
    this.resetError()
  }

  public getBlockPayload(cache?: boolean): MessageBlockDTO {
    const options = cache
      ? this.options
          .filter(
            (option) =>
              option.notifications[0].id || option.notifications[0].templateId
          )
          .map((option) => ({
            ...option,
            notifications: option.notifications.map((n) => ({ ...n }))
          }))
      : this.getOptionsDTO()

    return {
      name: this.name.value,
      options
    }
  }

  private getOptionsDTO() {
    return this.options.map((option) => ({
      notifications: option.notifications.map((n) => ({ ...n })),
      type: option.type,
      position: option.position
    }))
  }

  protected fillBlockPayload(data: MessageBlockDTO): void {
    this.name.setValue(data.name || '')

    if (!data.options?.length) {
      this.options = [
        {
          position: 0,
          id: uniqueId(),
          notifications: [{ id: null, position: 0 }],
          type: undefined,
          notification: undefined,
          isValid: true
        }
      ]
    } else {
      this.options = data.options.map((notification: INotification) => ({
        ...notification,
        position: Number(notification.position),
        id: uniqueId(),
        notification:
          notification.notification ||
          this.initializeNotification(notification.type),
        isValid: true
      }))
    }

    this.fetchAllNotifications(data)
  }

  public validateBlock(): boolean {
    this.validateName()
    this.validateNotifications()
    this.validateGoalTypes()

    if (!this.isValid) {
      this.launchErrors = ['You must set message']
    }
    return this.isValid
  }

  public validateName(): void {
    if (!this.name.validate()) {
      this.isValid = false
      this.errors = ['Enter Message Name']
    }
  }

  public validateNotifications(): void {
    if (this.options.length === 1 && !this.options[0].notification) {
      this.isValid = false
      this.options[0].isValid = false
      this.errors.push('Please choose at least one notification type')
      return
    }

    this.options.forEach((n) => {
      if (!n.notification) {
        n.isValid = false
        this.isValid = false
        this.errors.push(`You must choose notification type or delete it`)
        return
      }

      n.notification.validateStep()

      if (!n.notification.isStepValid || !n.notifications[0].id) {
        n.isValid = false
        this.isValid = false
        this.errors.push(`You must set up ${MCampaignTypeToName.get(n.type)}`)
      }
    })
  }

  public validateGoalTypes(): void {
    if (this.options.length === 1 || !this.isValid) {
      return
    }

    const goals = new Set(this.getGoalTypes())
    const isValid = this.options
      .slice(1)
      .filter(isNotification)
      .every((n) => {
        const types = getGoalTypesFromNotification(n.notification)
        if (types.length !== goals.size) {
          return false
        }
        return types.every((type) => goals.has(type))
      })

    if (!isValid) {
      this.isValid = false
      this.errors.push(
        'Notifications within message should include the same goals'
      )
    }
  }

  public resetError(): void {
    super.resetError()
    this.name.resetError()
    this.options.filter(isNotification).forEach((n) => {
      n.isValid = true
      n.notification.resetError()
    })
  }

  public setName(value: string): void {
    this.name.setValue(value)

    if (value) {
      this.resetError()
    }
  }

  private initializeNotification(
    type: CampaignType | NotificationType | undefined
  ): NotificationModel | undefined {
    if (!type) return undefined

    switch (type) {
      case NotificationType.PUSH:
        return new PushNotification({ app: this.currentApp })
      case NotificationType.ALERT:
        return new AlertNotification({
          isCampaign:
            this.parent.blockType === JourneyBlockType.START &&
            this.parent.builderType === 'campaign'
        })
      case NotificationType.IN_APP:
        return new InAppNotification({ app: this.currentApp })
      case CampaignType.SMS:
        return undefined
      case NotificationType.CARD:
        return new JourneyCard({ appId: this.currentApp.id })
      case NotificationType.EMAIL:
        return new JourneyEmailNotificationStore()
      default:
        // eslint-disable-next-line no-case-declarations
        const exhaustiveCheck: never = type
        throw new Error(exhaustiveCheck)
    }
  }

  public setNotification(
    notificationType: CampaignType | NotificationType,
    id: ID
  ): void {
    this.options = this.options.map((option) => {
      if (option.id === id) {
        return {
          ...option,
          type: notificationType,
          notification: this.initializeNotification(notificationType)
        }
      }
      return option
    })

    this.resetError()
  }

  public replaceNotificationPosition(destination: ID, source: ID): void {
    const destinationNotification = this.options.find(
      (n) => n.id === destination
    )
    const sourceNotification = this.options.find((n) => n.id === source)

    if (destinationNotification && sourceNotification) {
      const destinationPosition = destinationNotification.position
      const sourcePosition = sourceNotification.position

      destinationNotification.position = sourcePosition
      sourceNotification.position = destinationPosition
    }
  }

  private getEmptyNotificationOption(position?: number): INotification {
    const id = uniqueId()
    return {
      position: position ?? this.options.length,
      id,
      notifications: [{ id: null, position: 0 }],
      isValid: true
    }
  }

  public addEmptyNotification(): string {
    const n = this.getEmptyNotificationOption()
    this.options.push(n)

    this.resetError()
    return n.id
  }

  public removeNotification(id: ID): void {
    if (this.options.length === 1) {
      this.options = [this.getEmptyNotificationOption(0)]
    } else {
      this.options = this.options
        .filter((notification) => notification.id !== id)
        .sort((a, b) => a.position - b.position)
        .map((n, i) => ({ ...n, position: i }))
    }

    this.resetError()
  }

  private static parseNotification(notification: NotificationPayload) {
    if (
      notification.type === NotificationType.PUSH ||
      notification.type === NotificationType.ALERT
    ) {
      return {
        pushVariants: [notification]
      }
    }
    if (notification.type === NotificationType.IN_APP) {
      return {
        inAppNotification: {
          large: notification
        }
      }
    }

    return notification
  }

  public async saveNotification(
    id: ID,
    journeyId: ID | undefined
  ): Promise<void> {
    const notification = this.options.find((n) => n.id === id)
    if (!notification?.notification || !journeyId) {
      return
    }

    notification.notification.validateStep()
    if (!notification.notification.isStepValid) {
      throw new Error(CustomErrors.INVALID)
    }

    const result = await notification.notification.getPayload()
    const payloads = Array.isArray(result) ? result : [result]

    const savedNotifications = await Promise.all(
      payloads.map((payload) => {
        const notificationId = payload.id || notification.notifications[0].id

        const payloadWithJourneyId = { ...payload, journeyId }

        if (notificationId) {
          return updateNotification(
            this.currentApp.id,
            notificationId,
            payloadWithJourneyId
          )
        }
        return saveNotification(this.currentApp.id, payloadWithJourneyId)
      })
    )

    notification.notifications[0].id = savedNotifications[0].id

    this.fillNotification(savedNotifications[0].id, savedNotifications[0])
  }

  public fetchAllNotifications(data: MessageBlockDTO): void {
    this.fetchAllButtonCategories()
      .then(() => {
        if (data.options) {
          const promises = data.options.map((n) => {
            if (n.notifications[0].id) {
              return this.fetchNotification(n.notifications[0].id)
            }
            if (n.notifications[0].templateId) {
              return this.fetchNotificationTemplate({
                type: n.notifications[0].templateType,
                id: n.notifications[0].templateId
              })
            }

            return undefined
          })

          return Promise.all(promises)
        }
      })
      .then(() => {
        this.setReady()
      })
  }

  private fetchAllButtonCategories(): Promise<void[]> {
    return Promise.all(
      this.options
        .filter(isNotification)
        .filter((n) => n.type === CampaignType.PUSH)
        .map((n) => n.notification.shared.fetchButtonCategories())
    )
  }

  public async fetchNotification(
    notificationId: ID,
    abortSignal?: AbortSignal
  ): Promise<void> {
    try {
      const response = await fetchNotification(
        this.currentApp.id,
        notificationId,
        abortSignal
      )

      this.fillNotification(notificationId, response)
    } catch (error) {
      // TODO show message if notificaiton does not exist
      this.removeNotification(notificationId)
      console.error(error)
    }
  }

  public async fetchNotificationTemplate(
    notificationTemplate: { id: ID; type: 'personal' | 'global' },
    abortSignal?: AbortSignal
  ): Promise<void> {
    try {
      let response
      if (notificationTemplate.type === 'global') {
        response = await fetchDefaultNotificationTemplate(
          notificationTemplate.id,
          abortSignal
        )
      } else {
        response = await fetchNotificationTemplate(
          this.currentApp.id,
          notificationTemplate.id,
          abortSignal
        )
      }

      this.fillNotification(notificationTemplate.id, response)
    } catch (error) {
      // TODO show message if notificaiton does not exist
      this.removeNotification(notificationTemplate.id)
      console.error(error)
    }
  }

  private fillNotification(
    notificationId: ID,
    payload: NotificationPayload
  ): void {
    const notification = this.options.find(
      (n) =>
        n.notifications[0].id === notificationId ||
        n.notifications[0].templateId === notificationId
    )?.notification

    if (!notification) {
      return
    }

    const parsed = Message.parseNotification(payload)

    if (
      'pushVariants' in parsed &&
      (notification instanceof PushNotification ||
        notification instanceof AlertNotification)
    ) {
      notification.fillStore(parsed)
    } else if (notification instanceof JourneyEmailNotificationStore) {
      notification.fillStore(parsed)
    } else if (
      notification instanceof InAppNotification &&
      'inAppNotification' in parsed
    ) {
      notification.fillStore(parsed)
    } else {
      notification.fillStore(parsed)
    }
  }

  public rollbackNotification(notification: INotification): void {
    if (notification.notifications[0].id) {
      this.fetchNotification(notification.notifications[0].id)
    } else if (notification.notifications[0].templateId) {
      this.fetchNotificationTemplate({
        type: notification.notifications[0].templateType,
        id: notification.notifications[0].templateId
      })
    } else if (notification.type) {
      this.setNotification(notification.type, notification.id)
    }
  }

  protected getNodeData(): { label: string } {
    return { label: this.name.value || 'Message Name' }
  }

  protected async updateBlock(
    appId: ID,
    journeyId: ID,
    blockId: ID
  ): Promise<JourneyBlock> {
    const response = await updateBlock(
      appId,
      journeyId,
      blockId,
      this.getPayload()
    )
    this.notifySplit()
    return response
  }

  public getGoalTypes(): GoalType[] {
    if (this.options.length === 0 || !this.options[0].notification) {
      return []
    }

    const { notification } = this.options[0]
    return getGoalTypesFromNotification(notification)
  }

  public notifySplit(): void {
    const splits = getSplitNodesBeforeNextMessage(this.children[0])
    splits.forEach((split) => {
      if (split?.notify) {
        split.notify('currentGoals', this.getGoalTypes())
      }
    })
  }

  public getNotificationPreviews(): PartialPreview {
    return this.options
      .filter(isNotification)
      .sort((a, b) => a.position - b.position)
      .reduce(
        (aggr: PartialPreview, n) => {
          const p = getPreviewFromNotification(n.notification)

          if (!p) {
            return aggr
          }

          return {
            ...aggr,
            ...p,
            type: [...aggr.type, ...p.type]
          }
        },
        { type: [] }
      )
  }
}
